#header {
    background-color: #fff;
    padding: 0 10px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 300;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0
}

#content {
    background-color: #fff;
    /*margin: 20px 20px 20px 0;*/
    margin: 20px;
    padding: 20px;
    z-index: 200;
}

#sider {
    margin: 20px 0;
    /*margin: 20px 0px 20px 0;
    border-right: 20px solid #f0f2f5;*/
    /*max-height: calc(100vh - 104px);*/
}

.menu-toggle {
    font-size: 22px;
    padding: 10px;
    vertical-align: middle;
}

.main-title {
    margin: 0;
    text-align: center;
    font-size: 28px;
    position: relative;
    top: 3px;
    font-family: Gotham;
    color: #000000;
}

#footer {
    z-index: 199;
}

.loading-content {
    height: calc(100vh - 144px);
    text-align: center;
    padding-top: 50px;
}
.loading-big {
    font-size: 100px;
    vertical-align: middle;
    color: rgba(0,0,0,0.5);
}

@media only screen and (max-width: 480px) {

}
