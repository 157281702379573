.searchbar {
    margin-bottom: 24px;
}
.list {
    background-color: #fff;
}

.list a {
    border-bottom: 1px solid #f0f0f0;
}

.song-item {
    padding: 12px;
    cursor: pointer;
}

.list-divider {
    margin: 0;
}

.ant-list-empty-text {
    display: none;
}
