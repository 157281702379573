#menu {
    padding: 10px 20px;
    /*position: fixed;*/
    z-index: 198;
}

#menu .menu-button a {
    padding: 10px 0;
    display: block;
    font-size: 18px;
}
#menu .menu-button:not(.menu-footer) a {
    color: #000;

}
#menu .menu-button a.selected {
    color: #70002d;
}



@media only screen and (max-width: 767px) {
    #menu {
        padding: 0;
    }
    #menu .menu-footer {
        display: none;
    }
}
