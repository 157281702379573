.songTitle {
    margin: 0;
}

.song-title {
    font-size: 8vw;
}
.ant-page-header-heading-title {
    margin-right: 0;
    white-space: normal;
}

.song-content {
}

.trivia {
    font-style: italic;
    font-weight: bold;
    color: lightslategrey;
    margin-bottom: 20px;
}

.lyrics-wrapper {
    margin-bottom: 20px;
}

.lyrics {
    white-space: pre-line;
}

.page {
    font-style: italic;
}
