@font-face {
  font-family: 'Garamond';
  font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/AGaramondPro-Regular.otf');
}
@font-face {
  font-family: 'Gotham';
  font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/Gotham-Book.otf');
}
@font-face {
  font-family: 'Gotham-Book';
  font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/Gotham-Book.otf');
}
@font-face {
  font-family: 'Gotham-Bold';
  font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/Gotham-Bold.otf');

}
body {
    font-family: 'Garamond';
    font-size: 16px;
}

@media only screen and (max-width: 480px) {
    body {
        font-size: 3.5vw;
    }
}
